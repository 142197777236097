import React from 'react';
import { Layout } from 'antd';
import {HeaderMenu} from "./components/HeaderMenu";
import logo from "../../src/assets/logo.png";
import {CarouselPhoto} from "./components/CarouselPhoto";

const { Header, Content, Footer } = Layout;

export const DashboardLayout: React.FC = () => {


  return (
    <Layout>
      <Header style={{ position: 'sticky', top: 0, zIndex: 1, width: '100%', background: 'rgba(255, 255, 255)'}}>
        <div
            style={{
            float: 'left',
            width: 650, top:0,
            height: '100%',
            margin: '0px 0px 0px 0',
            background: 'rgba(255, 255, 255, 0.2)',
          }}
        >
            <img src={logo} style={{float:'left', marginLeft:'450px', width: '64px'}} alt="logo"/>
            <h2 style={{float:'left'}}>岭南猿开发</h2>
        </div>
          <div style={{float: 'left', marginLeft:'30px'}}>
          <HeaderMenu/>
          </div>
      </Header>
      <Content className="site-layout">
          <CarouselPhoto/>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
          <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023015971号-1</a>
      </Footer>
    </Layout>
  );
};

