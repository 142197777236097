import React from 'react';
import { Carousel } from 'antd';
import logo1 from "../../../src/assets/1.png";
import logo2 from "../../../src/assets/2.png";
import logo3 from "../../../src/assets/3.png";
import logo4 from "../../../src/assets/4.png";

export const CarouselPhoto: React.FC = () => (
  <Carousel autoplay>
    <div>
      <img src={logo1} style={{width: '100%'}} alt="/"/>
    </div>
    <div>
      <img src={logo2}  style={{width: '100%'}} alt="/"/>
    </div>
    <div>
      <img src={logo3}  style={{width: '100%'}} alt="/"/>
    </div>
    <div>
      <img src={logo4}  style={{width: '100%'}} alt="/"/>
    </div>
  </Carousel>
);
