import React, { useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps['items'] = [

  getItem('公众号', '1', null, [
    getItem((
              <a href="/">
                示例1
              </a>
            ), '2'),
    getItem((
              <a href="/">
                示例2
              </a>
            ), '3'),
    getItem((
              <a href="/">
                示例3
              </a>
            ), '4', null, [getItem((
                                              <a href="/">
                                                示例3-1
                                              </a>
                                            ), '7'),
                                            getItem((
                                              <a href="/">
                                                示例3-2
                                              </a>
                                            ), '8')]),
  ]),
    getItem('小程序', '9', null, [
        getItem((
                  <a href="/">
                    示例1
                  </a>
                ), '10'),
        getItem((
                  <a href="/">
                    示例2
                  </a>
                ), '11'),
      ]),
    getItem('网站', '12', null, [
        getItem((
                  <a href="/">
                    示例1
                  </a>
                ), '13'),
        getItem((
                  <a href="http://react.lnykf.tech/">
                    示例2
                  </a>
                ), '14'),
      ]),
    getItem('物联网', '15', null, [
        getItem((
                  <a href="/">
                    示例1
                  </a>
                ), '16'),
        getItem((
                  <a href="/">
                    示例2
                  </a>
                ), '17'),
      ]),
    getItem('DevOps', '18', null, [
        getItem((
                  <a href="/">
                    示例1
                  </a>
                ), '19'),
        getItem((
                  <a href="/">
                    示例2
                  </a>
                ), '20'),
      ]),
];



export const HeaderMenu: React.FC = () => {
  const [current, setCurrent] = useState('mail');

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
};
